import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {GrStripe} from "react-icons/gr";
import {withFirebase} from "../Firebase";
import {createCheckoutSession} from "@invertase/firestore-stripe-payments";

const SubscribeLink = (props) => {
    const [loading, setLoading] = useState(false);

    const createStripeSession = async () => {
        try {
            setLoading(true);
            const session = await createCheckoutSession(props.firebase.payments, {
                price: props.firebase.price,
                success_url: props.firebase.successUrl,
                trial_period_days: 60,
                payment_method_collection: 'if_required',
                billing_address_collection: 'auto',
            });
            window.location.assign(session.url);
        } catch (error) {
            console.error("Error creating Stripe session:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button onClick={createStripeSession} variant="primary" disabled={loading}><GrStripe/> Start free trial</Button>
    );
};

export default withFirebase(SubscribeLink);