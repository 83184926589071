import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";
import AdminSidebar from "./admin-sidebar";
import {Col, Container} from "react-bootstrap";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import {getProducts} from "@invertase/firestore-stripe-payments";

const ProductsList = (props) => {
    return (
        <Container className="content  min-vh-100">
            <div className="row pt-3">
                <Col sm={12} md={2}>
                    <AdminSidebar/>
                </Col>
                <Col sm={12} md={10}>
                    <ProductsListForm {...props}/>
                </Col>
            </div>
        </Container>
    )
}

const ProductsListForm = (props) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProductsAndPrices().then((value) => {
            setProducts(value);
            setLoading(false);
        }, (error) => {
            console.log("Error", error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProductsAndPrices = async () => {
        return await getProducts(props.firebase.payments, {
            includePrices: true,
            activeOnly: true,
        });
    }

    return (
        <>
            <h1>Dashboard | Products</h1>
            {loading && <div>Loading ...</div>}
            <Table striped hover size="sm" responsive>
                <thead>
                <tr>
                    <th>Product name</th>
                    <th>Role</th>
                    <th>Active</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {products.map(product => (
                    <tr key={product.id}>
                        <td>{product.name}</td>
                        <td>{product.role}</td>
                        <td>{product.active ? 'true' : 'false'}</td>
                        <td>
                            <Link to={`/admin/product/${product.id}`}>View</Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(ProductsList);