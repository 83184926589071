import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const ProductHero = () => {
    return (
        <div className="container-full-bg">
            <Container>
                <Row>
                    <Col xs={12} md={8} className="pt-5 jumbotron-left">
                        <div className="jumbotron-download">
                            <h1 className="jumbotron-title brand-font">Be the best.</h1>
                            <h2 className="jumbotron-paragraph">
                                For coaches and professional surfers.
                            </h2>
                        </div>
                        <div className="pt-4 product-text">
                            <p>
                                <b>PROSURFINGTOOLS</b> is a web application that <b> allow you to replicate
                                real-life surfing competitions</b>.</p>
                            <ul>
                                <li>Train with <b>real-time feedback</b>.</li>
                                <li>Simulate <b>different scenarios</b> and <b>improve your strategy</b>.</li>
                                <li>Get <b>insights</b> on your performance and <b>track your progress</b>.</li>
                            </ul>

                            <h3>Assemble your own team of coaches and surfers, and start today!</h3>

                        </div>
                        <div className="pt-2">
                            <a href="/signup" className="btn btn-primary btn-lg btn-home">START TRAINING!</a>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="jumbotron-right"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductHero;
