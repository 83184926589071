import React from 'react';
import {Link} from 'react-router-dom';
import {
    COOKIE_POLICY,
    LANDING,
    NON_DISCRIMINATION,
    PRIVACY_POLICY,
    SIGN_IN,
    SIGN_UP,
    TERMS
} from '../../constants/routes';
import {Container} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";

const styles = {
    hr: {
        borderTop: '3px solid #fabe08',
        width: '40px',
        margin: '0'
    }
};

export default function Footer() {
    return (
        <footer className="page-footer font-small pt-4 bg-black text-white">
            <Navbar className="justify-content-end version">
                <Container>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className="version-text">
                            version: 2.1.2
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="container text-center text-md-left py-5">
                <div className="row">
                    <div className="col-md-6 mt-md-0 mt-3">
                        <h5 className="text-uppercase">prosurfingtools</h5>
                        <hr style={styles.hr}/>
                        <p className='mt-3'>
                            Our web application allows you to replicate real-life surfing competition scenarios. You can
                            track your training progress, analyze and improve your performance under pressure, practice
                            wave selection and prioritization, and even compete against other surfers.
                        </p>
                        <p><b>Register now and start
                            your trial today!</b></p>
                    </div>
                    <div className="col-md-2 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Legal</h5>
                        <hr style={styles.hr}/>
                        <ul className="nav flex-column-footer mt-3">
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={PRIVACY_POLICY}>
                                    Privacy policy
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={COOKIE_POLICY}>
                                    Cookie policy
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={TERMS}>
                                    Terms of use
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={NON_DISCRIMINATION}>
                                    Non Discrimination Statement
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <hr className="clearfix w-100 d-md-none pb-3"/>
                    <div className="col-md-2 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Quick Links</h5>
                        <hr style={styles.hr}/>
                        <ul className="nav flex-column-footer mt-3">
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={LANDING}>
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={SIGN_IN}>
                                    Sign In
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link pl-0" to={SIGN_UP}>
                                    Register
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-2 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Media</h5>
                        <hr style={styles.hr}/>
                        <ul className="nav flex-column-footer mt-3">
                            <li className="nav-item">
                                <a className="nav-link pl-0"
                                   href="https://www.instagram.com/prosurfingtools" rel="noopener noreferrer"
                                   target="_blank">Instagram</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link pl-0"
                                   href="https://blog.prosurfingtools.com" rel="noopener noreferrer"
                                   target="_blank">Blog</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link pl-0"
                                   href="https://www.youtube.com/@prosurfingtools" rel="noopener noreferrer"
                                   target="_blank">YouTube</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">
                © 2021 Copyright <b>prosurfingtools</b>
            </div>
        </footer>
    );
}
