import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {withAuthorization, withEmailVerification} from '../Session';
import {getProduct} from "@invertase/firestore-stripe-payments";
import {Container, Row, Col} from "react-bootstrap";
import SubscribeCard from "./subscribe-card";
import SubscribeFaq from "./subscribe-faq";

const Subscribe = (props) => {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [price, setPrice] = useState(null);
    const [priceId] = useState(props.firebase.priceId());

    useEffect(() => {
        getProductAndPrice().then((product) => {
            // foreach product, get the price is equal to the price id
            product.prices.forEach((price) => {
                if (price.id === priceId) {
                    setPrice(price);
                }
            });
            setProduct(product);
            setLoading(false);
        }, (error) => {
            console.log("Error", error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProductAndPrice = async () => {
        return await getProduct(props.firebase.payments, props.firebase.product, {
            includePrices: true,
            activeOnly: true,
        });
    }

    return (
        <>
            {loading && <div>Loading ...</div>}
            {product &&
                <Container className="content p-2 h-100">
                    <Row>
                        <Col xs={12} md={3}>
                            <SubscribeCard product={product} price={price}/>
                        </Col>
                        <Col>
                            <SubscribeFaq/>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );

}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(Subscribe);