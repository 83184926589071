import React, {useState, useEffect} from 'react';
import {withAuthorization, withEmailVerification} from '../Session';
import {Button, ButtonToolbar, ButtonGroup, Card, Col, Container, Row, Form} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import validator from 'validator';
import Table from "react-bootstrap/Table";
import GetSurfer from "../Surfer/get-surfer";
import GetStatus from "./team-get-status";
import AcceptMember from "./team-accept-member";
import RemoveMember from "./team-remove-member";
import {compose} from "recompose";
import {RiUserSearchFill} from "react-icons/ri";
import {onValue} from "firebase/database";

const Team = (props) => {
    const [isLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);
    const [getCurrentTeamCallback, setGetCurrentTeamCallback] = useState(false);
    const [currentUser] = useState(props.firebase.currentUser().uid);
    const [currentSurfer] = useState(props.firebase.currentSurferData());
    const [teamList, setTeamList] = useState([]);
    const firebaseRef = props.firebase.surferTeam(currentUser);

    const getCurrentTeam = () => {
        const authorTeam = props.firebase.surferTeam(currentUser);
        if (authorTeam) {
            onValue(authorTeam, snapshot => {
                const teamObject = snapshot.val();
                let teamList = [];
                if (teamObject) {
                    Object.keys(teamObject).forEach(key => {
                        teamList.push(key);
                    });
                    setTeamList(teamList);
                } else {
                    setTeamList([]);
                }
                setGetCurrentTeamCallback(false);
            });
        }
    }

    useEffect(() => {
        onValue(firebaseRef, onFirebaseValueChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const onFirebaseValueChanged = snapshot => {
        getCurrentTeam();
    }

    useEffect(() => {
        getCurrentTeam();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrentTeamCallback]);

    const handleSelected = (surfer) => {
        setSelected(surfer);
        setOptions([]);
    }

    const handleSearch = (value) => {
        if (validator.isEmail(value)) {
            const data = ({email: value});
            const searchSurferByEmail = props.firebase.cloud('searchSurferByEmail');
            searchSurferByEmail(data).then(result => {
                const surfer = result.data;
                if (surfer && surfer.email !== currentSurfer.email) {
                    setOptions([surfer]);
                }
            });
        }
    }

    const handleInvite = (event) => {
        if (selected.length !== 0) {
            let selectedUid = selected[0].id;
            let selectedEmail = selected[0].email;
            let fullName = currentSurfer.name + ' ' + currentSurfer.surname;
            const inviteMember = props.firebase.cloud('inviteMember');
            inviteMember({uid: selectedUid}).then(() => {
                const sendEmail = props.firebase.cloud('requestEmail');
                sendEmail({uid: selectedUid, email: selectedEmail, fullName: fullName}).then(() => {
                    setGetCurrentTeamCallback(true);
                });
            });
        }
        event.preventDefault();
    }

    const handleAccept = (event, selectedUID) => {
        onValue(props.firebase.surferEmail(selectedUID), snapshot => {
            const selectedEmail = snapshot.val();
            const fullName = currentSurfer.name + ' ' + currentSurfer.surname;
            const acceptMember = props.firebase.cloud('acceptMember');
            acceptMember({uid: selectedUID}).then(() => {
                const acceptEmail = props.firebase.cloud('acceptEmail');
                acceptEmail({uid: selectedUID, email: selectedEmail, fullName: fullName}).then(() => {
                    setGetCurrentTeamCallback(true);
                });
            });
        });
    }

    const handleRemove = (event, selectedUID) => {
        const deleteMember = props.firebase.cloud('deleteMember');
        deleteMember({uid: selectedUID}).then(() => {
            setGetCurrentTeamCallback(true);
        });
    }

    const filterBy = () => true;

    return (
        <Container className="content p-2 h-100">
            <Row>
                <Col xs={12} md={3}>
                    <Card className="pt-3">
                        <Card.Header>Add surfer or coach</Card.Header>
                        <Card.Body>
                            <Form.Group className="form-element" controlId="team">
                                <Form.Label>
                                    <small className="text-muted">For security reason, type in the
                                        exact email from the user's account.
                                    </small>
                                </Form.Label>
                                <AsyncTypeahead
                                    filterBy={filterBy}
                                    id="async-example"
                                    isLoading={isLoading}
                                    labelKey="label"
                                    minLength={3}
                                    onSearch={handleSearch}
                                    onChange={(surfer) => {
                                        handleSelected(surfer)
                                    }}
                                    options={options}
                                    selected={selected}
                                    placeholder="Enter an email..."
                                    renderMenuItemChildren={(option, props) => (
                                        <div key={option.id}>
                                            <span>{option.label}</span>
                                        </div>
                                    )}
                                />
                            </Form.Group>
                        </Card.Body>
                        <ButtonGroup className="d-flex">
                            <Button onClick={handleInvite} variant="primary">
                                <RiUserSearchFill/> Add member
                            </Button>
                        </ButtonGroup>
                    </Card>
                </Col>
                <Col xs={12} md={9}>
                    <Card className="pt-3">
                        <Card.Header>My Team</Card.Header>
                        <Card.Body>
                            <Table striped hover size="sm" responsive>
                                <thead>
                                <tr>
                                    <th>Full name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {teamList && teamList.map(uid => (
                                    <tr key={uid}>
                                        <td><GetSurfer uid={uid}/></td>
                                        <td><GetSurfer uid={uid} email={true}/></td>
                                        <td><GetStatus uid={uid} currentUser={currentUser}/></td>
                                        <td>
                                            <ButtonToolbar aria-label="actions">
                                                <ButtonGroup className="me-2" aria-label="accept group">
                                                    <AcceptMember uid={uid} currentUser={currentUser}
                                                                  handleAccept={handleAccept}/>
                                                </ButtonGroup>
                                                <ButtonGroup className="me-2" aria-label="Second group">
                                                    <RemoveMember uid={uid} handleRemove={handleRemove}/>
                                                </ButtonGroup>
                                            </ButtonToolbar>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(Team);